import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Mancaruri from './pages/Mancaruri/Mancaruri';
import Meniu from './pages/Meniu/Meniu';
import MeniulZilei from './pages/MeniulZilei/MeniulZilei';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mancaruri" element={<Mancaruri />} />
          <Route path="/meniu" element={<Meniu />} />
          <Route path="/meniul-zilei" element={<MeniulZilei />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
