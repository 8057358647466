import React from 'react';
import './Mancaruri.css';

function Mancaruri() {
  return (
    <div className="mancaruri-page">
      <div className="mancaruri-container">
        <h1 className="mancaruri-title">Mancaruri</h1>

      </div>
    </div>
  );
}

export default Mancaruri;