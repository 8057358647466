import React, { useEffect, useState } from 'react';
import './Hero.css';
import { throttle } from 'lodash';

function Hero() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setOffset(window.pageYOffset);
    }, 16);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="hero-section">
      <div 
        className="hero-banner-container"
        style={{ transform: `translateY(${offset * 0.5}px)` }}
      >
        <img 
          src="../../assets/images/yawe-banner-full.jpg" 
          alt="YAWE Hero Banner" 
          className="hero-banner"
        />
      </div>
      <div className="hero-content">
        <h1 className="hero-title">
          <p>Platouri și preparate pentru evenimente</p>
        </h1>
        <div className="hero-subheading">
          <p>• Zile de naștere</p>
          <p>• Petreceri private</p>
          <p>• Evenimente speciale / religioase</p>
        </div>
      </div>
    </section>
  );
}

export default Hero; 