import './Action.css';

function Action() {
  const phoneNumber = "+40772124233";
  
  return (
    <section id="contact" className="action-section">
      <div className="action-container">
        <h2 className="action-title">Contactați-ne pentru comenzi</h2>
        <div className="action-buttons">
          <a href={`tel:${phoneNumber}`} className="action-button phone">
            <i className="fas fa-phone"></i>
            <span>{phoneNumber}</span>
          </a>
          <a 
            href={`https://wa.me/${phoneNumber.replace('+', '')}`} 
            className="action-button whatsapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
            <span>WhatsApp</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Action; 