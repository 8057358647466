import React from 'react';
import Navbar from '../Navbar/Navbar';
import './Header.css';

function Header() {
  return (
    <header>
      <Navbar />
      {/* Add any other header content here */}
    </header>
  );
}

export default Header;