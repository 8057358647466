import React, { useEffect,  useState} from 'react';
import './Meniu.css';
import { throttle } from 'lodash';

function Meniu() {

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setOffset(window.pageYOffset);
    }, 16);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="meniu-page">
      <div className="meniu-container">

      <div 
        className="menu-image-container"
        style={{ transform: `translateY(${offset * 0.5}px)` }}
      >
        <img 
          src="../../assets/images/winter-background-2-smaller.jpg"
          alt="winter background"

          className="menu-image"
        />
      </div>
        <h1 className="meniu-title">Meniu Yawe</h1>
        <div className="menu-section">
          <div className="menu-card">
            <h2>Meniu 1 - 27 Lei</h2>
            <ul>
              <li>•⁠ Grătar/Șnițel/Crispy de pui</li>
              <li>•⁠ Cartofi prăjiți/Piure/Pilaf/Cartofi cu rozmarin</li>
              <li>•⁠ Salată de varză albă</li>
              <li>•⁠ Chiflă</li>
            </ul>
          </div>
          <div className="menu-card">
            <h2>Meniu 2 - 37 Lei</h2>
            <ul>
              <li>•⁠ Ciorbă la alegere</li>
              <li>•⁠ Chiftele marinate + garnitură / Aripioare de pui la cuptor + garnitură</li>
              <li>•⁠ Salată de varză albă</li>
              <li>•⁠ Chiflă</li>
            </ul>
          </div>
        </div>

        <div className="menu-section">
          <div className="menu-card">
            <h2>Ciorbe</h2>
            <ul>
              <li>•⁠ Ciorbă de burtă - 21 Lei</li>
              <li>•⁠ Supă cremă de dovleac (de post) - 15 Lei</li>
            </ul>
          </div>
          <div className="menu-card">
            <h2>Garnituri</h2>
            <ul>
              <li>•⁠ Cartofi prăjiți - 8 Lei</li>
              <li>•⁠ Piure de cartofi - 10 Lei</li>
              <li>•⁠ Pilaf - 10 Lei</li>
              <li>•⁠ Chiflă - 2 Lei</li>
            </ul>
          </div>
        </div>

        <div className="menu-section">
          <div className="menu-card">
            <h2>Grătar</h2>
            <ul>
              <li>•⁠ Piept de pui - 17 Lei</li>
              <li>•⁠ Ceafă de porc - 17 Lei</li>
              <li>•⁠ Pulpă de pui dezosată - 17 Lei</li>
              <li>•⁠ Cârnați proaspeți - 15 Lei</li>
            </ul>
          </div>
          <div className="menu-card">
            <h2>Salate</h2>
            <ul>
              <li>•⁠ Salată de varză albă - 5 Lei</li>
              <li>•⁠ Salată de castraveți la oțet - 7 Lei</li>
              <li>•⁠ Salată de gogoșari - 6 Lei</li>
              <li>•⁠ Salată de sfeclă roșie - 5 Lei</li>
            </ul>
          </div>
        </div>

        <div className="menu-section">
          <div className="menu-card">
            <h2>Desert</h2>
            <ul>
              <li>•⁠ Plăcintă cu dovleac - 10 Lei</li>
              <li>•⁠ Plăcintă cu vișine - 10 Lei</li>
              <li>•⁠ Plăcintă cu mere - 10 Lei</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meniu;
