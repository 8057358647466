import React from 'react';
import './MeniulZilei.css';

function MeniulZilei() {
  return (
    <div className="meniul-zilei-page">
      <div className="meniul-zilei-container">
        <h1 className="meniul-zilei-title">Meniul Zilei</h1>
        {/* Add your menu content here */}
      </div>
    </div>
  );
}

export default MeniulZilei; 