import React, { useState, useEffect } from 'react';
import './Navbar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Close menu when window is resized beyond mobile breakpoint
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.navbar')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const scrollToContact = (e) => {
  //   e.preventDefault();
  //   const contactSection = document.querySelector('.action-section');
  //   if (contactSection) {
  //     // Check if we're on mobile
  //     const isMobile = window.innerWidth <= 768;
      
  //     if (isMobile) {
  //       // On mobile, scroll to the section with some offset from the top
  //       const navbarHeight = document.querySelector('.navbar').offsetHeight;
  //       const yOffset = -navbarHeight; // Adjust this value if needed
  //       const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
        
  //       window.scrollTo({
  //         top: y,
  //         behavior: 'smooth'
  //       });
  //     } else {
  //       // On desktop, use regular scrollIntoView
  //       contactSection.scrollIntoView({ 
  //         behavior: 'smooth',
  //         block: 'start'
  //       });
  //     }
      
  //     setIsMenuOpen(false);
  //   }
  // };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/" className="logo-link">
          <img 
            src="/assets/images/yawe-restaurant-white.png" 
            alt="Yawe Restaurant Logo" 
            className="navbar-logo" 
          />
        </a>
      </div>
      <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        <a href="/" className="nav-link" onClick={() => setIsMenuOpen(false)}>Acasă</a>
        <a href="/meniu" className="nav-link" onClick={() => setIsMenuOpen(false)}>Meniu Yawe</a>
        {/* <a href="/mancaruri" className="nav-link" onClick={() => setIsMenuOpen(false)}>Mâncăruri</a> */}
        {/* <a href="/meniul-zilei" className="nav-link" onClick={() => setIsMenuOpen(false)}>Meniul Zilei</a> */}
        {/* <a href="/bauturi" className="nav-link" onClick={() => setIsMenuOpen(false)}>Băuturi</a> */}
        {/* <a 
          href="#contact" 
          className="nav-link" 
          onClick={scrollToContact}
        >
          Contact
        </a> */}
      </div>
      <button 
        className="mobile-menu-button"
        onClick={toggleMenu}
        aria-label="Meniu"
        aria-expanded={isMenuOpen}
      >
        <span className={`hamburger ${isMenuOpen ? 'active' : ''}`}></span>
      </button>
    </nav>
  );
}

export default Navbar; 