import React from 'react';
import Hero from '../../components/Hero/Hero';
import Action from '../../components/Action/Action';
import Meniu from '../../components/Meniu/Meniu';
import Map from '../../components/Map/Map';

// ... import other components you need

function Home() {

  return (
    <>
      <Hero />
      <Action />
      <Meniu />
      <Map />
    </>
  );
}

export default Home; 