import React from 'react';
import './Map.css';

function Map() {
  // Replace these coordinates with your actual location
  const address = "Yawe AUTOSERVIRE";
  const coordinates = "44.448647294046886, 26.131020738883244"; // Example: New York coordinates

  const openGoogleMaps = () => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`, '_blank');
  };

  const openWaze = () => {
    window.open(`https://waze.com/ul?ll=${coordinates}&navigate=yes`, '_blank');
  };

  return (
    <section className="map-section">
      <div className="map-container">
        <h2 className="map-title">Unde ne găsiți?</h2>
        <div className="map">
          <iframe
            title="Google Maps Location"
            src="https://www.google.com/maps/embed?pb=!1m27!1m12!1m3!1d342.71450938129595!2d26.130991441712574!3d44.44859997552824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m12!3e0!4m4!1s0x40b1f9d4d74b281f%3A0x15f66e0555b3f63b!3m2!1d44.4486384!2d26.1310193!4m5!1s0x40b1f9d4d74b281f%3A0x15f66e0555b3f63b!2sYAWE%20autoservire%2C%20%C8%98oseaua%20Pantelimon%202%2C%20Bucure%C8%99ti%20021591!3m2!1d44.4486384!2d26.1310193!5e0!3m2!1sro!2sro!4v1731926286758!5m2!1sro!2sro"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
          <div className="map-buttons">
            <button onClick={openGoogleMaps} className="map-button google">
              <img src="../../assets/icons/g-maps-icon.png" alt="Google Maps" />
              Deschideti G-Maps
            </button>
            <button onClick={openWaze} className="map-button waze">
              <img src="../../assets/icons/waze-icon.png" alt="Waze" />
              Deschideti Waze
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map; 