import React, { useEffect, useState } from 'react';
import './Meniu.css';
import { throttle } from 'lodash';

function Meniu() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setOffset(window.pageYOffset);
    }, 16);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="meniu-section">
      <div 
        className="meniu-banner-container"
        style={{ transform: `translateY(${offset * 0.5}px)` }}
      >
        <img 
          src="../../assets/images/meniu-small.jpg" 
          alt="Menu Background" 
          className="meniu-banner"
        />
      </div>
      <div className="meniu-content">
        <div className="meniu-container">
          <h2 className="meniu-title">Meniul Nostru</h2>
          <div className="meniu-buttons">
            {/* <a href="/meniul-zilei" className="meniu-button daily">
              <span>Meniul Zilei</span>
            </a> */}
            <a href="/meniu" className="meniu-button food">
              <span>Meniu Yawe</span>
            </a>
            {/* <a href="/bauturi" className="meniu-button drinks">
              <span>Mâncăruri</span>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Meniu; 