import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Contact</h3>
          <p>
            <i className="fas fa-map-marker-alt"></i>
            Șoseaua Pantelimon 2, București 021591
          </p>
          <p>
          <a href="tel:+40 772 124 233">
            <i className="fas fa-phone"></i></a>
            +40 772 124 233
            
          </p>
        </div>

        <div className="footer-section">
          <h3>Program</h3>
          <p>Luni - Vineri: 11:00 - 17:00</p>
          <p>Sâmbătă - Duminică: Închis</p>
        </div>

        <div className="footer-section">
          <h3>Social Media</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/share/cUwaVhCMyVm4bNf8/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/yawe_autoservire?igsh=YWcydXZ0bzYwOW50" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a 
              href="https://www.tiktok.com/@yawe.autoservire?_t=8rUeOc325uI&_r=1" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>© 2024 YAWE RESTAURANT - Toate drepturile rezervate.</p>
      </div>
    </footer>
  );
}

export default Footer; 